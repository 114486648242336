import {
  Button,
  Input,
  Select,
  Space,
  Table,
  Tag,
  Modal,
  Checkbox,
} from "antd";
import { useEffect, useState } from "react";
import { request } from "../../system/request";
import JoditEditor from "jodit-react";
import { openNotification } from "../../share/message";
const { Option } = Select;
const Home = () => {
  const [id, setID] = useState("");
  const [enable, setEnable] = useState(false);
  const [disable, setDisable] = useState(false);
  const [visableModel, setModelVisable] = useState(false);
  const [slectCategory, setslectCategory] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const [title, setTitle] = useState("");
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("");
  const [ListCategory, setListCategory] = useState([]);

  useEffect(() => {
    GetList();
    GetListEdit();
  }, []);
  const handleCreatePost = async () => {
    const param = new URLSearchParams({
      post_title: title,
      post_cover: coverImageUrl,
      post_content: content,
      post_category: category,
      status: enable == true ? "1" : "0",
      id: id,
    });

    try {
      const method = id === null ? "post" : "put";
      const res = await request("newposts?" + param.toString(), method);
      if (res && res.success) {
        openNotification(
          "success",
          "Success",
          id === null ? " Save is Sucess" : "Update Post is Sucess"
        );
        setTitle("");
        setCategory("");
        setCoverImageUrl("");
        setContent("");
        setModelVisable(false);
        GetList();
      }
    } catch (error) {
      openNotification("error", "Error", error.message);
    }
  };
  const GetList = async () => {
    const res = await request("posts", "get", {});
    if (res) {
      setDataSource(res.posts_API);
      console.log("this  is list from server:", res);
    } else {
      console.log("somthing is wrong ");
    }
  };
  const GetListEdit = async () => {
    try {
      const res = await request("posts", "get", {});
      if (res && res.category_API) {
        setListCategory(res.category_API);
      } else {
        console.log("Error fetching categories");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const no_cover = "https://parliament.gov.gy/assets/css/Images/no_cover.png";
  const columns = [
    {
      title: "Posts Cover",
      dataIndex: "post_cover",
      key: "post_cover",
      render: (post_cover) => {
        return (
          <div
            style={{ width: 200, height: 120, border: 1, borderColor: "blue" }}
          >
            <img
              style={{ width: 200, height: 120 }}
              src={post_cover || no_cover}
              alt="Cover Image"
              onError={(e) => {
                e.target.onerror = null; // Prevents infinite loop if fallback image fails
                e.target.src = no_cover;
              }}
            />
          </div>
        );
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Posts Title",
      dataIndex: "post_title",
      key: "post_title",
    },
    {
      title: "Category",
      dataIndex: "post_category",
      key: "post_category",
    },

    {
      title: "Create Date",
      dataIndex: "post_date",
      key: "create_date",
    },
    {
      title: "Create By",
      dataIndex: "post_by",
      key: "create_by",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <div>
            <Tag
              color={status == 1 ? "#108ee9" : "#f50"}
              style={{
                width: 100,
                height: 30,
                fontWeight: "bold",

                alignContent: "center",
              }}
            >
              {status == 1 ? "Active" : "Not Active"}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (item, record) => {
        return (
          <div>
            <Space>
              <Button type="primary" onClick={() => onOpenEdit(record)}>
                Edit
              </Button>
              <Button danger type="primary">
                Delete
              </Button>
            </Space>
          </div>
        );
      },
    },
  ];
  const onCahngeCategory = () => {};
  const onOpenEdit = (record) => {
    setID(record.id);
    setTitle(record.post_title);
    setCoverImageUrl(record.post_cover);
    setContent(record.post_content);
    setCategory(record.idcategory);
    record.status === 1 ? setEnable(true) : setDisable(true);
    setModelVisable(true);
  };

  const onChangeContents = (e) => {
    setContent(e);
  };

  const onChangeCategory = (value) => {
    setCategory(value);
  };
  const handleResetContent = () => {
    setID(null);
    setTitle("");
    setCoverImageUrl("");
    setContent("");
    setCategory("");
  };
  const onCreateNew = () => {
    setID(null);
    setEnable(true);
    setDisable(false);
    setModelVisable(true);
    handleResetContent();
  };
  const handleCheckboxChange_disable = () => {
    setDisable(true);
    setEnable(false);
  };
  const handleCheckboxChange_enable = () => {
    setEnable(true);
    setDisable(false);
  };
  const [coverPreview, setCoverPreview] = useState("");
  const onChangeCoverImage = (e) => {
    setCoverImageUrl(e.target.value);
    setCoverPreview(coverImageUrl);
  };
  return (
    <div>
      <div>
        <Button type="primary" onClick={onCreateNew}>
          Create New
        </Button>
      </div>
      <div>
        <h3>Manage Posting on Website</h3>
      </div>
      <div style={{ paddingBottom: 10 }}>
        <Space>
          <Input
            placeholder="Search Post..."
            style={{ width: 200 }}
            type="text"
          />
          <Select
            style={{ width: 200 }}
            value={slectCategory}
            onChange={onCahngeCategory}
            placeholder="select "
          >
            <Option Value="coding">Coding</Option>
            <Option Value="coding">Coding1</Option>
            <Option Value="coding">Coding1</Option>
          </Select>
        </Space>
      </div>
      <div>
        <Table dataSource={dataSource} columns={columns} />;
      </div>

      <Modal
        width={1000}
        open={visableModel}
        onCancel={() => {
          setModelVisable(false);
          setEnable(false);
          setDisable(false);
        }}
        title={id === null ? "Create New" : "Update Posts"}
        footer={
          <div>
            <Space style={{ paddingTop: 20 }}>
              <Button type="primary" onClick={handleCreatePost}>
                {id === null ? " Create Post" : "Update Post"}
              </Button>
              <Button type="primary" danger onClick={handleResetContent}>
                Reset Content
              </Button>
            </Space>
          </div>
        }
      >
        <div style={{ paddingLeft: 40, paddingRight: 40 }}>
          <h4>What going in your posts today?</h4>
          <div>
            <p style={{ color: "blue", fontWeight: "bold" }}>Post title *</p>
            <Input
              style={{ height: 40 }}
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div>
            <p style={{ color: "blue", fontWeight: "bold" }}>
              Post Cover URL *
            </p>
            <Input
              style={{ height: 40 }}
              type="text"
              value={coverImageUrl}
              onChange={onChangeCoverImage}
            />
            <div style={{ width: 200, height: 100, padding: 10 }}>
              <img
                style={{ width: 200, height: 100 }}
                src={coverImageUrl}
                alt="cover-pre"
              />
            </div>
          </div>
          <div>
            <p style={{ color: "blue", fontWeight: "bold" }}>Post Content *</p>
            <JoditEditor value={content} onChange={onChangeContents} />
          </div>
          <div style={{ paddingTop: 10 }}>
            <p style={{ color: "blue", fontWeight: "bold" }}>Category *</p>
            <Select
              style={{ width: 250, height: 35 }}
              placeholder="Select a Category"
              onChange={onChangeCategory}
              value={category}
            >
              {ListCategory.map((option) => (
                <Option key={option.id} value={option.id}>
                  {option.Name}{" "}
                  {/* Replace with the correct field from your MySQL table */}
                </Option>
              ))}
            </Select>
            <Tag color="magenta" style={{ marginLeft: 20 }}>
              <div style={{ padding: 10 }}>
                <Checkbox
                  checked={enable}
                  onChange={handleCheckboxChange_enable}
                >
                  Enable
                </Checkbox>
                <Checkbox
                  style={{ color: "red" }}
                  checked={disable}
                  onChange={handleCheckboxChange_disable}
                >
                  Disable
                </Checkbox>
              </div>
            </Tag>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Home;
