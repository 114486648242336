import React, { useEffect } from "react";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import "./Layout.css";
import { Outlet, useNavigate } from "react-router-dom";
import { isGetLogin } from "../../share/getProfile";
const items = [
  { key: 1, label: "Home" },
  { key: 2, label: "Create Posts" },
  { key: 3, label: "Logout System" },
];
const { Header, Content, Footer } = Layout;
const MasterLayout = () => {
  useEffect(() => {
    if (!isGetLogin()) {
      window.location.href = "/login"; // if not login  it will go back to login page
    }
  });
  const navigate = useNavigate();
  const onLinkPage = (route) => {
    navigate(route);
  };
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const onCLikMenuItem = (e) => {
    if (e.key === "1") {
      onLinkPage("/home");
    } else if (e.key === "2") {
      onLinkPage("/create");
    } else if (e.key === "3") {
      window.localStorage.clear();
      window.location.href = "/login"; // if not login  it will go back to login page
    }
  };
  if (!isGetLogin()) {
    return null; // if not login  it will go back to login page
  }
  return (
    <Layout>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["1"]}
          items={items}
          onClick={onCLikMenuItem}
          className="custom-menu"
          style={{
            flex: 1,
            minWidth: 0,
          }}
        />
      </Header>
      <Content
        style={{
          padding: "0 48px",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
          }}
        >
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{
            background: colorBgContainer,
            minHeight: 280,
            padding: 24,
            borderRadius: borderRadiusLG,
          }}
        >
          <Outlet />
        </div>
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        Dev Computing ©{new Date().getFullYear()} Created by Dev Computing.
      </Footer>
    </Layout>
  );
};
export default MasterLayout;
