import React, { useEffect, useState } from "react";
import JoditEditor from "jodit-react";
import { Button, Input, Select, Space, Checkbox, Tag } from "antd";
import { request } from "../../system/request";
import { openNotification } from "../../share/message";
const { Option } = Select;

const PostEditor = () => {
  const [title, setTitle] = useState("");
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("");
  const [ListCategory, setListCategory] = useState([]);

  const handleCreatePost = async () => {
    const param = new URLSearchParams({
      post_title: title,
      post_cover: coverImageUrl,
      post_content: content,
      post_category: category,
      status: 1,
    });

    try {
      const res = await request("newposts?" + param.toString(), "post");
      if (res && res.success) {
        openNotification("success", "Success", " Save is Sucess");
        setTitle("");
        setCategory("");
        setCoverImageUrl("");
        setContent("");
      }
    } catch (error) {
      openNotification("error", "Error", error.message);
    }
  };

  useEffect(() => {
    GetList();
  }, []);

  const GetList = async () => {
    try {
      const res = await request("posts", "get", {});
      if (res && res.category_API) {
        setListCategory(res.category_API);
      } else {
        console.log("Error fetching categories");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleResetContent = () => {
    setTitle("");
    setCoverImageUrl("");
    setContent("");
    setCategory("");
  };

  const onChangeContents = (e) => {
    setContent(e);
  };

  const onChangeCategory = (value) => {
    setCategory(value);
  };

  return (
    <div style={{ padding: 40 }}>
      <h4>What going in your posts today?</h4>
      <div>
        <p style={{ color: "blue", fontWeight: "bold" }}>Post title *</p>
        <Input
          style={{ height: 40 }}
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div>
        <p style={{ color: "blue", fontWeight: "bold" }}>Post Cover URL *</p>
        <Input
          style={{ height: 40 }}
          type="text"
          value={coverImageUrl}
          onChange={(e) => setCoverImageUrl(e.target.value)}
        />
      </div>
      <div>
        <p style={{ color: "blue", fontWeight: "bold" }}>Post Content *</p>
        <JoditEditor value={content} onChange={onChangeContents} />
      </div>
      <div style={{ paddingTop: 20 }}>
        <p style={{ color: "blue", fontWeight: "bold" }}>Category *</p>
        <Select
          style={{ width: 250, height: 35 }}
          placeholder="Select a Category"
          onChange={onChangeCategory}
          value={category}
        >
          {ListCategory.map((option) => (
            <Option key={option.id} value={option.id}>
              {option.Name}{" "}
              {/* Replace with the correct field from your MySQL table */}
            </Option>
          ))}
        </Select>
        <Tag color="magenta" style={{ marginLeft: 20 }}>
          <div style={{ padding: 10 }}>
            <Checkbox
            // checked={enable_car}
            // onChange={handleCheckboxChange_Enable_Car}
            >
              Enable
            </Checkbox>
            <Checkbox
              style={{ color: "red" }}
              // checked={disable_car}
              // onChange={handleCheckboxChange_Diable_Car}
            >
              Disable
            </Checkbox>
          </div>
        </Tag>
      </div>

      <Space style={{ paddingTop: 20 }}>
        <Button type="primary" onClick={handleCreatePost}>
          Create Post
        </Button>
        <Button type="primary" danger onClick={handleResetContent}>
          Reset Content
        </Button>
      </Space>
    </div>
  );
};

export default PostEditor;
